import React, { useState } from "react"
import Layout from "../components/layout"
import SplashScreen from "../components/splashScreen"
import Slider from "../components/slider"
import Decouvrir from "../components/decouvrir"
import img1 from "../images/accueil1.png";
import img2 from "../images/accueil2.png";
const IndexPage = () => {
  const [splashActive, setSplashActive] = useState( true);
  const splashFinish = async () => {
    await import('../videos/KAMM FINAL SHORT.mp4').then( () => {
      setTimeout( () => {
        setSplashActive(false);
      }, 500);
    });
  }
  return(
    <>
      {splashActive && (
        <SplashScreen siteTitle="Accueil" splashFinish={ () => splashFinish()}/>
      )}
      <Layout className={(!splashActive) ? 'layout layout-loaded' : 'layout'}>
        <Slider />
        <section>
          <div className="section-1">
            <h1
              className="mix-title"
              data-sal="slide-up"
              data-sal-delay="1000"
              data-sal-duration="1000"
              data-sal-easing="ease">L8 <span style={{marginLeft: "-35px"}}>MOTIV</span>
            </h1>
            <Decouvrir
              to="/etat-d-esprit"
              data-sal="slide-up"
              data-sal-delay="1000"
              data-sal-duration="1000"
              data-sal-easing="ease" />
            <img src={img1} alt="Etat d'esprit" />
            <h2  data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
            </h2>
            <h3  data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">Concevoir <br/>Beau, sain et <br/>durable.
            </h3>
          </div>
          <div className="section-2">
            <h1
              className="mix-title"
              data-sal="slide-up"
              data-sal-delay="500"
              data-sal-duration="1000"
              data-sal-easing="ease">Z8 <span style={{marginLeft: "-35px"}}>GEIST</span>
            </h1>
            <Decouvrir
              to="/etat-d-esprit"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="1000"
              data-sal-easing="ease" />
            <h2  data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
            </h2>
            <h3  data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease">Cycle d'intuition <br/>D'intention <br/>et d'attention
            </h3>
          </div>
          <div className="section-3">
            <img src={img2} alt="h8 Love" />
            <h1
              className="mix-title"
              data-sal="slide-up"
              data-sal-delay="500"
              data-sal-duration="1000"
              data-sal-easing="ease">H8 <span style={{marginLeft: "-35px"}}>Love</span>
            </h1>
            <Decouvrir
              to="/etat-d-esprit"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="1000"
              data-sal-easing="ease" />
            <h2  data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
            </h2>
            <h3  data-sal="slide-up"
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease">Tout ce qui <br/>est conçu <br/>doit être <br/>perçu
            </h3>
          </div>
        </section>
      </Layout>
    </>
   )
}

export default IndexPage
