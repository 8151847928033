import React, { useEffect, useState } from "react"
import SEO from "../seo";
import logo from "../../images/logo.png"
import icon from "../../images/logo_sm_black.png"
import logo_text from "../../images/logo-no-icon.png"

import "./style.scss"

const SplashScreen = ({splashFinish = () => {}, siteTitle}) => {
  const [logoLoad, setLogoLoad] = useState(false);

  useEffect( () => {
    const url = window.location;
    if(url.search === '?s=0'){
      splashFinish();
    }else{
      setTimeout( () => {
        setLogoLoad(true);
      }, 2000);
      setTimeout( () => {
        setLogoLoad(false);
      }, 3500);
      setTimeout( () => {
        splashFinish();
      }, 3500);
    }

  }, [])
  return (
    <div className="splash">
      <SEO title={siteTitle} />
      <img src={logo} alt="logo" className={(logoLoad ? "logoSplash default loaded": "default logoSplash")} />
      <div>
        <img src={icon} alt="logo" className={(logoLoad ? "logoSplash icon loaded": "icon logoSplash")} />
        <img src={logo_text} alt="logo" className={(logoLoad ? "logoSplash text loaded": "text logoSplash")} />
      </div>
    </div>
  )
}

export default SplashScreen
