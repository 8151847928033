import React from "react"

import Decouvrir from "../decouvrir"
import "./style.scss"


const Slide = ({index, slide, sliderIndex}) => {

    return (
        <div>
            {slide.video ? (
                <video muted autoPlay loop style={{ opacity: `${(index === sliderIndex) ? '1' : '0'}`}}>
                    <source src={slide.video} type="video/mp4" />
                </video>
            ) : (
                <picture>
                    {slide.imgWebp && (
                        <source srcSet={slide.imgWebp} type="image/webp" />
                    )}
                    <source srcSet={slide.img} type="image/jpeg" />
                    <img src={slide.img} alt={slide.title} style={{opacity: `${(index === sliderIndex) ? '1' : '0'}`}} />
                </picture>
            )}

            <div style={{zIndex: `${(index === sliderIndex) ? '1' : '-2'}`, opacity: `${(index === sliderIndex) ? '1' : '0'}`}}>
                <div>
                    <div>
                        <div>
                            <h2>{slide.title}</h2>
                            <Decouvrir to={slide.url} />
                        </div>
                        <div>
                            <h4>{slide.subTitle}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Slide
