import React, { useEffect, useState } from "react"
//import duo from "../../images/duo.jpg"
//import duoWebp from "../../images/duo.webp"
import brasserie from "../../images/brasserie.jpg"
import brasserieWebp from "../../images/brasserie.webp"
import brasserieDubillot from "../../images/DUBILLOT-06_1.jpg";
import brasserieMartin from "../../images/MARTIN 02_big.jpg";
import maisonKammerzell from "../../images/Kammerzell_01.jpg"
import videoKammerzell from '../../videos/KAMM FINAL SHORT.mp4';
// import etatEsprit from "../../images/accueil slider-etat d'esprit-etat d'esprit.png";
// import duo from "../../images/accueil-slider-duo.png";
import "./style.scss"
import Slide from "./slide";

const Slider = () => {
    const slider = [
        {
            img: maisonKammerzell,
            video: videoKammerzell,
            title: "MAISON\nKAMMERZELL",
            subTitle: "Cette ancienne Maison, qui prend ses origines en 1427, \nest le résultat d’une composition vernaculaire qui en marque les différentes étapes",
            url: "/projets/maison-kammerzell/",
        },{
            img: brasserieDubillot,
            title: "BRASSERIE\nDUBILLOT",
            subTitle: "Dans cette belle rue Saint-Denis sans cesse en évolution, \nc’est à l’angle du 222 sur deux niveaux que Nouvelle Garde décide d’implanter sa deuxième Brasserie.",
            url: "/projets/brasserie-dubillot/",
        },{
            img: brasserieMartin,
            title: "BRASSERIE\nMARTIN",
            subTitle: "A proximité du Square Gardette, cette nouvelle Brasserie \ns’installe dans un lieu connu du voisinage, avec la particularité de réunir les deux parties du restaurant.",
            url: "/projets/brasserie-martin/",
        }
        // ,
        // {
        //     img: etatEsprit,
        //     title: "état \nd'esprit",
        //     subTitle: "Notre approche du projet et de notre énergie au service des idées, \nce sont les 80% qui ne se voient pas mais qui font la réussite du résultat visible.",
        //     url: "/etat-d-esprit",
        // },{
        //     img: duo,
        //     title: "Le \nDuo",
        //     subTitle: "Avec notre double parcours, nous tentons de nous inscrire \ndans une trajectoire cohérente et équilibrée, comme les deux brins de l'ADN.",
        //     url: "/duo",
        // }
    ];

    const [forceUpdate, setForceUpdate] = useState( 0);
    const [sliderIndex, setSlideIndex] = useState(0);

    useEffect( () => {
      const interval = setInterval(() => {
        setSlideIndex(sliderIndex => (sliderIndex === (slider.length - 1)) ? 0 : sliderIndex + 1)
      }, 10000);
      return () => clearInterval(interval);
    }, [forceUpdate]);

    const changeSlide = (direction) => {
      setSlideIndex(
        (direction === 'right')
          ? (sliderIndex === (slider.length - 1)) ? 0 : sliderIndex + 1
          : sliderIndex === 0 ? slider.length - 1 : sliderIndex - 1);
      setForceUpdate(forceUpdate + 1);
    }

    return (
      <div className="slider">
        <div>
          <div className="arrow-left" onClick={ () => changeSlide('left')}><div/></div>
          {slider.map( (slide,index) => (
            <Slide slide={slide} index={index} sliderIndex={sliderIndex}/>
          ))}
        </div>
        <div className="arrow-right" onClick={ () => changeSlide('right')}><div/></div>
      </div>
    )
}

export default Slider
